.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}



.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><line x1="12" y1="16" x2="12" y2="12"/><line x1="12" y1="8" x2="12" y2="8"/></svg>');
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.tooltip .tooltipText {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: var(--colorWhite);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: var(--colorTooltipText);
  text-align: left;
  border-radius: 6px;
  padding: 16px;
  width: 200px;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
}

.tooltip:hover .tooltipText {
  display: block;
}

.helperMessage {
  margin-top: 8px;
}